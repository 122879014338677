@import url(../fonts/geist/stylesheet.css);
@import url(../fonts/geist_mono/stylesheet.css);
@import url("https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,200..800;1,6..72,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400..700;1,400..700&display=swap");

:root {
  --font-sans: "Geist Sans", sans-serif;
  --font-familjen: "Familjen Grotesk", sans-serif;
  --font-serif: "Newsreader", serif;
  --font-mono: "Geist Mono", monospace;
  --heading-font: var(--font-sans);
  --body-font: var(--font-sans);
  --link-font: var(--font-sans);
  --mono-font: var(--font-mono);
  --font-features: "calt" 1, "dlig" 1, "cv11" 1, "cv12" 1, "cv13" 1 !important;
  // Line Height
  --line-height-xl: 79px;
  --line-height-lg: 45px;
  --line-height-md: 24px;
  // Letter Spacing
  --tracking-zero: 0px;
  --tracking-tightest: -1.75px;
  --tracking-tighter: -0.5px;
  --tracking-tight: -0.5px;
  // Font Sizes
  --font-xxl: 70px;
  --font-xl: 34px;
  --font-lg: 26px;
  --font-md: 20px;
  --font-smm: 1rem;
  --font-base: 0.9rem;
  --font-sm: 0.87rem;
  --font-xs: 0.82rem;
  --font-xxs: 0.78rem;
  --font-xxxs: 0.7rem;
  // Font Weight
  --font-bold: 700;
  --font-semibold: 600;
  --font-normal: 400;
  --font-normal-var: 400;
  --font-medium: 500;
  --font-light: 300;
  // Font Margins
  --font-margin-normal: 20px;
}

* {
  font-feature-settings: var(--font-features);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  font-weight: var(--font-bold);
  color: var(--fg-color);
  padding-right: 2px;
  padding-bottom: 2px;
}

strong {
  font-family: var(--font-sans);
  font-weight: var(--font-normal);
}

h1 {
  font-size: var(--font-xxl);
  line-height: var(--line-height-xl);
  letter-spacing: var(--tracking-tightest);
  margin-bottom: 25px;

  @include at-width($bp-md) {
    font-size: var(--font-xl);
    line-height: calc(var(--line-height-xl) - 35px);
    letter-spacing: calc(var(--tracking-tightest) + 2px);
  }
}

h2 {
  font-family: var(--heading-font);
  font-size: var(--font-lg);
  font-weight: var(--font-bold);
  line-height: var(--line-height-lg);
  letter-spacing: var(--tracking-tighter);
  margin-bottom: 10px;

  @include at-width($bp-md) {
    font-size: var(--font-md);
    line-height: calc(var(--line-height-lg) - 5px);
  }
}

p,
span {
  font-size: var(--font-base);
  font-family: var(--body-font);
  font-weight: var(--font-normal);
  line-height: var(--line-height-md);
  margin: 0 0 var(--font-margin-normal) 0;
  letter-spacing: var(--tracking-zero);
  color: var(--fg-body);
  font-feature-settings: var(--font-features);

  strong {
    font-family: var(--font-sans);
    font-weight: var(--font-bold);
  }

  a {
    font-size: var(--font-base);
    font-family: var(--body-font);
    font-weight: var(--font-normal);
    color: var(--dark-50);
    letter-spacing: var(--tracking-zero);
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
  }

  code {
    background-color: var(--code-bg);
    color: var(--code-hi);
    padding: 0 5px;
    border-radius: 3.5px;
  }

  &.coming-soon {
    padding: 10px 0;
    margin-bottom: 0;
  }

  @include at-width($bp-md) {
    font-size: calc(var(--font-sm) - 0.05rem);

    a {
      font-size: (--font-xs);
    }
  }
}

li {
  font-size: var(--font-sm);
  font-family: var(--body-font);
  font-weight: var(--font-normal);
  line-height: var(--line-height-md);
  letter-spacing: var(--tracking-zero);
  color: var(--fg-body);

  strong {
    font-family: var(--font-sans);
    font-weight: var(--font-bold);
  }

  &.coming-soon {
    padding: 10px 0;
    margin-bottom: 0;
  }

  code {
    background-color: var(--code-bg);
    color: var(--code-hi);
    padding: 0 5px;
    border-radius: 3.5px;
  }
}

a,
small {
  font-size: var(--font-xs);
  font-family: var(--link-font);
  font-weight: var(--font-normal);
  letter-spacing: var(--tracking-zero);
  text-decoration: none;
  color: var(--dark-50);

  &.button {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    min-width: 100px;
    width: fit-content;
    padding: 10px 20px;
    margin: 10px 0;
    text-decoration: none;
    background: var(--bg-color);
    color: var(--fg-body);
    font-weight: var(--font-medium);
    border-radius: 10px;
    box-shadow: 0px 0px 0px 2px var(--button-border);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      width: 110%;
      height: 110%;
      left: -5%;
      top: -5%;
      background: var(--border-bg);
      border-radius: 10px;
      filter: blur(10px);
      opacity: 0;
      transition: opacity 0.4s ease;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: var(--bg-color);
      border-radius: 10px;
      opacity: 1;
      transition: opacity 0.4s ease;
    }

    &:hover {
      &:before {
        opacity: 1;
        transition: opacity 0.5s ease-out;
      }
    }
  }

  @include at-width($bp-md) {
    font-size: calc(var(--font-xs) - 0.08rem);
  }
}

small {
  font-size: var(--font-xxs);
  font-family: var(--link-font);
  font-weight: var(--font-normal);
  letter-spacing: var(--tracking-zero);
  text-decoration: none;
  color: var(--fg-body);

  a {
    font-size: var(--font-xxs);
    color: var(--dark-50);
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-style: dashed;
  }
}

figure {
  width: auto;
}

.italics {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.dashed {
  text-decoration: underline !important;
  text-decoration-style: dashed !important;
  text-underline-offset: 3px;
}

pre,
code,
.code,
.gutter .gl {
  font-family: var(--mono-font);
  font-size: var(--font-xxs) !important;
  font-weight: var(--font-normal);
  font-feature-settings: "calt" on !important;

  @include at-width($bp-sm) {
    font-size: var(--font-xxs) !important;
  }

  span {
    font-family: var(--mono-font);
    font-size: var(--font-xxs) !important;
    line-height: 18px;
    font-weight: var(--font-normal);

    @include at-width($bp-sm) {
      font-size: var(--font-xxs) !important;
    }
  }
}
