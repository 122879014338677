@charset "utf-8";

@import "colors";
@import "mixins";
@import "typography";
@import "syntax";
@import "variables";

html {
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antiliased !important;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: var(--bg-color);
}

/*
    Icons
*/
.icon {
  color: var(--fg-body-color);

  &__small {
    width: 17px;
    height: 17px;
  }
}

/*
  Layout
*/
.layout {
  &__inner {
    position: relative;
  }
}

/*
    Header & Navigation
*/
.desktop-nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bg-color);
  z-index: 999;
  box-shadow: 0 0 0 1px var(--border);

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    box-shadow: 0 0 0 1px var(--border);
    padding: 0 20px;
    box-sizing: border-box;
  }

  &__button {
    cursor: pointer;
    border: none;

    svg {
      width: 16px;
      vertical-align: middle;
      color: var(--dark-50);
      transform: scale(0);
      transition: transform 0.3s ease-out 0s;

      &.active {
        transform: scale(1);
        transition: transform 0.3s ease-out 0s;
      }
    }
  }

  &__links {
    margin: 15px 0;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 30px;
    list-style-type: none;
    padding: 2.5px 0;

    @include at-width($bp-sm) {
      margin: 20px 0;
    }
  }

  &__link {
    a,
    a:focus,
    a:visited,
    a:active {
      text-decoration: none;
    }
  }
}

.mobile-nav {
  display: none;
}

/*
    Container
*/
.container {
  margin-left: auto;
  margin-right: auto;

  @include below-width($bp-xs) {
    max-width: $bp-xs - 10px;
    width: $bp-xs - 10px;
  }

  @include below-width($bp-sm) {
    max-width: $bp-sm - 40px;
  }

  @include below-width($bp-md) {
    max-width: $bp-md - 100px;
    width: $bp-md - 100px;
  }

  @include below-width($bp-lg) {
    max-width: $bp-lg - 250px;
    width: $bp-lg - 250px;
  }

  @include below-width($bp-xl) {
    max-width: $bp-xl - 600px;
    width: $bp-xl - 600px;
  }

  &.narrow {
    @include below-width($bp-md) {
      max-width: $bp-md - 200px;
      width: $bp-md - 200px;
    }

    @include below-width($bp-lg) {
      max-width: $bp-lg - 300px;
      width: $bp-lg - 300px;
    }

    @include below-width($bp-xl) {
      max-width: $bp-lg - 350px;
      width: $bp-lg - 350px;
    }
  }
}

/*
    Hero
*/
.hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 200px 20px 50px 20px;
  box-shadow: 0 0 0 1px var(--border);
  box-sizing: border-box;

  @include at-width($bp-md) {
    padding: 150px 10px;
  }

  @include at-width($bp-sm) {
    padding: 120px 10px;
  }
}

.hero-text {
  max-width: 60ch;
  display: flex;

  &__centered {
    text-align: center;
    justify-content: center;
  }
}
.hero-subtext {
  max-width: 70ch;

  @include at-width($bp-md) {
    max-width: 45ch;
  }

  &__centered {
    margin-top: 30px;
    max-width: 70ch;
    text-align: center;
    display: flex;
    align-self: center;
  }
}

.hero-short {
  max-width: 45ch;
}

/*
    Home
*/
.projects {
  @include below-width($bp-xl) {
    margin: 70px 0;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1px;
  }

  &__image {
    background-image: var(--dotted-bg);
    background-size: 35px 35px;
    border: 3px solid var(--border);
    height: 550px;
    object-fit: cover;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 550px;
      border-radius: 10px;

      @include at-width($bp-sm) {
        width: 275px;
      }
    }

    @include at-width($bp-sm) {
      height: 250px;
    }
  }

  &__grid-item {
    height: auto;
    width: 100%;
    background-color: var(--bg-color);
    padding: 16px 20px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px var(--border);

    .title {
      margin-bottom: 0;
    }

    p {
      width: fit-content;
      margin-bottom: 0;

      @include at-width($bp-sm) {
        max-width: 42ch;
      }
    }

    &:hover {
      background-color: var(--accent);
    }

    @include at-width($bp-md) {
      padding: 16px 10px;
    }

    @include at-width($bp-sm) {
      padding: 16px 10px;
    }
  }

  &__title {
    width: auto;
    white-space: nowrap;
  }

  &__divider {
    width: 100%;
    border-style: dashed;
    border-width: 0.15px;
    border-color: var(--dark-40);
  }

  &__hr {
    width: 100%;
    background-color: var(--fg-body-color);
  }

  &__grid-item-picture {
    margin: 0;
    padding: 0;
    height: 320px;
    border-radius: 10px;
    border: 0.75px solid var(--border);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    background-image: var(--dotted-bg);
    background-size: 30px 30px;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: var(--fg-body-color);
    }

    @include at-width($bp-sm) {
      height: 320px;
    }
  }

  &__image-hover-effect {
    z-index: 0;
    backface-visibility: hidden;
    position: relative;
    overflow: hidden;
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    border-style: inset;
    border-radius: 12px;
    margin: 0;
    background: var(--bg-color);

    i {
      opacity: 0;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 2.5px;
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      z-index: 2;
      border-radius: 12px;
      transition: opacity 0.5s ease;

      &:before {
        content: "";
        display: block;
        background: var(--border-bg);
        box-shadow: 0 0 40px 20px var(--border-bg);
        width: calc(100% * 1.4);
        padding-bottom: calc(100% * 1.4);
        position: absolute;
        left: -20%;
        top: -30%;
        transform-origin: center;
        border-radius: 100%;
        z-index: 1;
        animation: rotate 5s linear infinite;
      }
    }

    img {
      width: 360px;
      object-fit: cover;
      border-radius: 10px;
      transition: all 0.3s ease-out;

      @include at-width($bp-sm) {
        width: 300px;
      }
    }
  }

  a {
    &:hover {
      i {
        opacity: 1;
        transition: opacity 0.5s ease;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

/*
  About
*/
.about {
  &__image {
    img {
      width: 200px;
      border-radius: 15px;
    }
  }
}

/*
  Qualities
*/

.qualities {
  &__container {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    @include at-width($bp-md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
    }

    @include at-width($bp-sm) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }

  &__grid-item {
    margin: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

/*
  Experiences
*/

.experiences {
  &__container {
    margin-top: 1px;
    box-shadow: 0 0 0 1px var(--border);
    padding: 40px 20px;
    box-sizing: border-box;
    overflow: hidden;

    @include at-width($bp-md) {
      padding: 40px 10px;
    }

    @include at-width($bp-sm) {
      padding: 40px 10px;
    }
  }

  &__line {
    opacity: 0.1;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 35px;
    margin: 30px 0 0 0;

    @include at-width($bp-md) {
      grid-template-columns: 1fr;
      gap: 35px;
    }

    @include at-width($bp-sm) {
      grid-template-columns: 1fr;
      gap: 35px;
    }
  }

  &__heading {
    margin-bottom: 0;
  }

  &__image {
    width: 50px;
    object-fit: contain;
  }

  &__position {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    @include at-width($bp-sm) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__link {
    text-decoration: none;
    color: var(--fg-body-color);
    font-size: var(--font-base);
  }

  &__grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include at-width($bp-sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:hover {
      .experiences__link {
        text-decoration: underline;
      }
    }
  }
}

/*
 Colophon
*/
.colophon {
  &__container {
    margin-top: 1px;
    box-shadow: 0 0 0 1px var(--border);
    padding: 20px;
    box-sizing: border-box;

    p {
      margin: 0;
    }

    @include at-width($bp-md) {
      padding: 10px;
    }

    @include at-width($bp-sm) {
      padding: 10px;
    }
  }
}

/* Interests */
.interests {
  &__container {
    margin-bottom: 100px;
  }

  &__line {
    opacity: 0.1;
  }

  &__list {
    margin-top: 10px;
  }
}

/*
  Photos
*/
.photos {
  &__hero {
    padding: 150px 20px 75px 20px;
    box-shadow: 0 0 0 1px var(--border);
    box-sizing: border-box;

    @include at-width($bp-md) {
      padding: 150px 10px 55px 10px;
    }

    @include at-width($bp-sm) {
      padding: 120px 10px 20px 10px;
    }
  }

  &__grid {
    margin-top: 1px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    box-sizing: border-box;

    @include at-width($bp-sm) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: grid;
    height: 100%;
    width: 100%;
    background: var(--bg-color);
    position: relative;

    box-shadow: 0 0 0 1px var(--border);
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      min-height: 302.5px;
      max-height: 605px;
      object-fit: cover;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 2.5px 8px;

    p {
      margin: 0;
      color: var(--dark-50);
    }
  }
}

/*
  Blog
*/
.blog {
  &__container {
    position: relative;
  }

  &__main {
    width: 100%;
  }

  &__pagination {
    margin-top: 80px;
    margin-bottom: 0;

    .flex {
      margin-top: 20px;
      width: fit-content;
      gap: 15px;

      p,
      span {
        margin: 0;
      }

      &__button {
        font-size: var(--a-size);
        background-color: var(--bg-color);
        color: var(--fg-body-color);
        border: 1px solid var(--border);
        border-radius: 7.5px;
        padding: 5px 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        svg {
          width: 15px;
        }

        &__disabled {
          color: var(--dark-30);
          cursor: not-allowed;
        }
      }
    }
  }

  &__grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 50px;

    @include at-width($bp-md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include at-width($bp-sm) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    margin: 0;
    background: transparent;
    transition: border ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    box-shadow: 0 0 0 2px var(--border);
    border-radius: 7.5px;
    padding: 20px 15px;
    transition: box-shadow ease-in-out 0.1s;

    img {
      width: 100%;
      height: 200px;
      border-radius: 12px;
      object-fit: cover;
    }

    &:hover {
      box-shadow: 0 0 0 2px var(--border);
      transition: box-shadow ease-in-out 0.1s;
    }

    @include at-width($bp-md) {
      grid-template-columns: 1fr;
      gap: 5px;
    }

    @include at-width($bp-sm) {
      grid-template-columns: 1fr;
      gap: 5px;
    }
  }

  &__thumbnail_details {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  &__thumbnail_title {
    margin-bottom: 0;
    justify-self: flex-start;
  }

  &__content {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    align-items: flex-start;
    gap: 35px;
    position: relative;
    margin-top: 100px;

    h2 {
      margin-top: 0;
    }

    .date {
      position: sticky;
      top: 10%;
      left: 0;
      justify-self: flex-start;
      height: fit-content;

      small {
        display: block;
        margin-bottom: 15px;
      }

      h2,
      p {
        margin: 0;
      }

      @include at-width($bp-sm) {
        position: relative;
      }
    }

    @include at-width($bp-md) {
      grid-template-columns: 0.5fr 1fr;
      gap: 35px;
    }

    @include at-width($bp-sm) {
      grid-template-columns: 1fr;
      gap: 35px;
    }
  }
}
/*
  Article
*/
.article {
  svg {
    width: var(--a-size);
    height: var(--a-size);
    vertical-align: middle;
    padding-right: 5px;
    padding-bottom: 1.5px;
  }

  &__image {
    position: relative;
    background-image: var(--dotted-bg);
    background-size: 35px 35px;
    border: 3px solid var(--border);
    height: 530px;
    padding: 25px;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;

    h1 {
      margin-top: 20px;
      margin-bottom: 0px !important;
    }

    @include at-width($bp-sm) {
      height: 250px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130vh;
    background: var(--overlay-bg-color);

    @include at-width($bp-sm) {
      height: 90vh;
    }
  }
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.col {
    flex-direction: column;
  }

  &__gap-sm {
    gap: 5px;
  }
}

img {
  width: 100%;
}

/*
  Footer
*/
.footer {
  margin-top: 1px;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 1px var(--border);

  &__heading {
    margin: 0 0 10px 0;
  }

  &__container {
    height: 100%;
    margin-top: 1px;
    padding: 20px;
    box-shadow: 0 0 0 1px var(--border);
    box-sizing: border-box;

    @include at-width($bp-md) {
      padding: 10px;
    }

    @include at-width($bp-sm) {
      padding: 10px;
    }
  }

  &__flex {
    display: flex;
    align-items: start;
    gap: 100px;

    @include at-width($bp-lg) {
      gap: 55px;
    }

    @include at-width($bp-md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 45px;
    }

    @include at-width($bp-sm) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }

  &__links-heading {
    margin: 0;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 9px;
    width: fit-content;

    a {
      display: block;
      text-decoration: none;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 75px 0 0 0;
  }

  &__bottom-lead {
    margin-bottom: 0;
  }

  &__bottom-end {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  &__theme-btn {
    font-size: var(--a-size);
    background-color: var(--bg-color);
    color: var(--fg-body-color);
    border: none;
    box-shadow: 0 0 0 1px var(--border);
    border-radius: 7.5px;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
      padding: 0;
      line-height: normal;
    }

    svg {
      width: 15px;
      vertical-align: middle;
    }
  }
}

.bookmarks {
  &__container {
    margin-top: 1px !important;
    padding: 20px 0;
    box-shadow: 0 0 0 1px var(--border);
    box-sizing: border-box;

    ul {
      margin: 0 !important;
    }
  }

  p {
    margin: 7.5px 0;
  }
}

table {
  @extend .container;

  color: var(--fg-body-color);
  font-family: var(--body-font);
  font-size: var(--a-size);
  text-align: left;
  border-collapse: collapse;
  overflow: hidden;
  overflow-y: auto;

  th,
  td {
    border: 1px solid var(--fg-body-color);
    padding: 5px 10px;
    white-space: nowrap;
  }
}

.highlighter-rouge {
  @extend .container;
  margin-left: 0;
}

* {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
