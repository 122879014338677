// Font Family
$fonts: (
  "sans": var(--font-sans),
  "familjen": var(--font-familjen),
  "serif": var(--font-serif),
  "mono": var(--font-mono),
  "geist": var(--font-geist),
);

@each $name, $family in $fonts {
  .font-#{$name} {
    font-family: $family;
  }
}

// Font Sizes
$font-sizes: (
  "xxl": var(--font-xxl),
  "xl": var(--font-xl),
  "lg": var(--font-lg),
  "md": var(--font-md),
  "smm": var(--font-smm),
  "base": var(--font-base),
  "sm": var(--font-sm),
  "xs": var(--font-xs),
  "xxs": var(--font-xxs),
  "xxxs": var(--font-xxxs),
);

@each $name, $size in $font-sizes {
  .font-#{$name} {
    font-size: $size;
  }
}

// Font Weight
$font-weights: (
  "bold": var(--font-bold),
  "semibold": var(--font-semibold),
  "medium": var(--font-medium),
  "normal": var(--font-normal),
  "light": var(--font-light),
  "var": var(--font-normal-var),
);

@each $name, $weight in $font-weights {
  .font-#{$name} {
    font-weight: $weight;
    font-variation-settings: "wght" $weight;
  }
}

// Letter Spacing
$trackings: (
  "zero": var(--tracking-zero),
  "tightest": var(--tracking-tightest),
  "tighter": var(--tracking-tighter),
  "tight": var(--tracking-tight),
);

@each $name, $tracking in $trackings {
  .tracking-#{$name} {
    letter-spacing: $tracking;
  }
}

// Colors
$colors: (
  "black": var(--black),
  "white": var(--white),
  "off-white": var(--off-white),
  "dark-05": var(--dark-05),
  "dark-10": var(--dark-10),
  "dark-20": var(--dark-20),
  "dark-30": var(--dark-30),
  "dark-50": var(--dark-50),
  "dark-60": var(--dark-60),
  "dark-70": var(--dark-70),
  "dark-80": var(--dark-80),
  "dark-90": var(--dark-90),
  "dark-95": var(--dark-95),
  "code-hi": var(--code-hi),
  "code-bg": var(--code-bg),
);

@each $name, $color in $colors {
  .#{$name} {
    color: $color;
  }

  .bg-#{$name} {
    background-color: $color;
  }
}
