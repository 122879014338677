$bp-xs: 360px;
$bp-sm: 480px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1280px;

// Breakpoints
@mixin below-width($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin at-width($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}
