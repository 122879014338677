:root {
  --black: rgb(4, 5, 6);
  --white: rgb(255, 255, 255);
  --off-white: rgb(242, 242, 242);

  --dark-05: rgb(250, 250, 250);
  --dark-10: rgb(244, 244, 245);
  --dark-20: rgb(228, 228, 231);
  --dark-30: rgb(212, 212, 216);
  --dark-40: rgb(161, 161, 170);
  --dark-50: rgb(113, 113, 122);
  --dark-60: rgb(82, 82, 91);
  --dark-70: rgb(63, 63, 70);
  --dark-80: rgb(39, 39, 42);
  --dark-90: rgb(24, 24, 27);
  --dark-95: rgb(9, 9, 11);

  --code-bg: rgb(64, 64, 64);
  --code-hi: rgb(113, 196, 255);

  --photo-text: rgb(250, 204, 21);
}

html {
  &.light {
    --bg-color: var(--white);
    --fg-color: var(--black);
    --fg-body: var(--dark-70);
    --accent: var(--dark-05);
    --border: var(--dark-20);
    --dotted-bg: radial-gradient(rgb(205, 205, 205) 5%, var(--bg-color) 5%);
  }

  &.dark {
    --bg-color: var(--dark-90);
    --fg-color: var(--dark-30);
    --fg-body: var(--dark-30);
    --accent: var(--dark-80);
    --border: var(--dark-80);
    --dotted-bg: radial-gradient(rgb(50, 50, 50) 5%, var(--bg-color) 5%);
  }
}
